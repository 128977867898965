<template>
  <div>
    <div class="flex-row flex items-center lg:hidden s-mobile-navbar">
      <div
        class="s-navbar-menu flex flex-row overflow-y-auto overflow-x-visible"
        :class="{ opened: xIsNavbarOpen }"
      >
        <div
          class="s-menu-header s-top-bar-address-dropdown flex text-xs pt-2 pb-4 w-full items-center justify-center"
          :class="{ 'h-full': placesMenuOpen }"
          aria-haspopup="true"
        >
          <PageHeaderPickupDeliveryControls
            :context="'mobile-menu'"
            @open="openModal"
            @close="$emit('close')"
            @open-places-menu="openPlacesMenu"
            @close-places-menu="closePlacesMenu"
          />
        </div>

        <template v-if="!placesMenuOpen">
          <div class="s-menu-logo flex items-center justify-center p-5 text-center">
            <NuxtLink :to="'/'">
              <SalveImage
                url="/images/brand/logo-menu.png"
                alt="The Flowery"
                width="75%"
                class="mx-auto"
                loading="lazy"
              />
            </NuxtLink>
          </div>

          <ShopPageLink
            class="s-menu-btn-order text-lg text-center"
            @click="closeMobileNavbar"
          >
            Order Now
          </ShopPageLink>

          <div class="s-menu-personal-info flex flex-column w-full">
            <NuxtLink
              v-if="xIsAuthenticated"
              :to="'/profile/dashboard'"
              class="s-menu-btn-my-acc inline-flex justify-between items-center w-full mb-1 mt-3"
            >
              <div class="flex flex-row w-full">
                <div class="s-menu-btn-my-acc-content flex flex-row items-center justify-center p-3 w-full">
                  <IconAccount class="s-menu-btn-my-acc-user-svg" />
                  <div>
                    <div class="header text-xs">My Account</div>
                    <div class="user text-xl">{{ shortname }}</div>
                  </div>
                  <IconCaretDown class="s-menu-btn-my-acc-arrow-right mr-2" />
                </div>
              </div>
            </NuxtLink>

            <div
              v-else
              class="s-menu-btn-my-acc my-5 grid grid-cols-2 gap-3 w-full"
            >
              <div
                class="s-menu-btn-my-acc-content flex flex-col py-5 text-center cursor-pointer"
                @click="xOpenLoginPopup"
              >
                <div class="header text-xs">My Account</div>
                <span class="text-xl">Login</span>
              </div>

              <NuxtLink
                :to="'/registration'"
                class="s-menu-btn-my-acc-content flex flex-col py-5 text-center cursor-pointer"
              >
                <div class="header text-xs">Not A Member</div>
                <span class="text-xl">Join</span>
              </NuxtLink>
            </div>
          </div>

          <AccordionMenu title="menu">
            <div class="s-menu-weed">
              <div class="s-menu-weed-content flex flex-row">
                <div class="w-1/2 text-base flex flex-col">
                  <div
                    v-for="category of GET_HEADER_DATA.weed_categories"
                    :key="category.weed_categoriy_title"
                    class="weed-item"
                  >
                    <SalveLink
                      :to="getCategoryLink(category.weed_category_link)"
                      @hyperlink="gotHyperlink"
                    >
                      {{ category.weed_categoriy_title }}
                    </SalveLink>
                  </div>
                </div>

                <div class="w-1/2 text-base flex flex-col">
                  <div
                    v-for="lineage of GET_HEADER_DATA.weed_lineage"
                    :key="lineage.weed_lineage_title"
                    class="weed-item"
                  >
                    <SalveLink
                      :to="lineage.weed_lineage_object"
                      @hyperlink="gotHyperlink"
                    >
                      {{ lineage.weed_lineage_title }}
                    </SalveLink>
                  </div>
                </div>

                <div class="w-full grid grid-cols-2 gap-2 justify-between mt-8 items-center">
                  <template
                    v-for="(brand, i) of GET_HEADER_DATA.weed_brands"
                    :key="i"
                  >
                    <SalveLink
                      :to="brand.weed_brand_object"
                      @hyperlink="gotHyperlink"
                    >
                      <span class="s-image"
                        ><img
                          :src="brand.weed_brand_image"
                          alt=""
                      /></span>
                    </SalveLink>
                  </template>
                </div>
              </div>
            </div>
          </AccordionMenu>

          <AccordionLink to="/loyalty-program">loyalty</AccordionLink>

          <AccordionMenu title="resources">
            <div class="px-3 pt-3">
              <div class="s-menu-weed-content flex flex-col">
                <div class="w-full flex flex-col text-black text-sm">
                  <NuxtLink
                    class="s-dropdown-resource-link block"
                    :to="'/about'"
                  >
                    > About The Flowery
                  </NuxtLink>
                  <NuxtLink
                    class="s-dropdown-resource-link block mt-1"
                    :to="'/doctors'"
                    >> How-To Get a Medical Card
                  </NuxtLink>
                  <NuxtLink
                    class="s-dropdown-resource-link block mt-1"
                    :to="'/resources'"
                  >
                    > What are Terpenes?
                  </NuxtLink>
                  <NuxtLink
                    class="s-dropdown-resource-link block mt-1"
                    :to="'/doctors'"
                  >
                    > Find a Doctor
                  </NuxtLink>
                  <NuxtLink
                    class="s-dropdown-resource-link block mt-1"
                    :to="`/delivery-regions-coverage`"
                  >
                    > Delivery Regions Coverage
                  </NuxtLink>
                </div>
                <div class="border-b border-gray-100 my-3"></div>
                <div class="w-full text-base flex flex-row">
                  <div class="flex flex-col w-1/2">
                    <NuxtLink
                      class="weed-item pb-2"
                      :to="'/media'"
                      >Media + Events</NuxtLink
                    >
                    <NuxtLink
                      class="weed-item"
                      :to="'/'"
                      >Contact</NuxtLink
                    >
                  </div>
                </div>
                <div class="border-b border-gray-100 my-3"></div>
                <div class="w-full flex flex-col">
                  <div class="s-dropdown-resource-title">Find a Location</div>
                  <NuxtLink to="/locations">
                    <SalveImage
                      url="/images/dropdown-location.png"
                      alt="Find a Flowery location"
                      width="150"
                      height="140"
                    />
                  </NuxtLink>
                </div>
              </div>
              <div class="border-b border-gray-100 my-3"></div>
            </div>
          </AccordionMenu>

          <AccordionLink
            v-if="xIsAuthenticated"
            @click="logoutCurrentUser"
            >logout</AccordionLink
          >

          <div class="s-menu-resources-content mt-auto">
            <div class="address my-2 mb-5">
              <a href="tel:3059122929">(305) 912-2929</a><br />
              <a href="mailto:hello@theflowery.co">hello@theflowery.co</a><br />
              Proudly Grown in Homestead, FL<br />
              Hours: M-F 8am to 8pm<br />
              <a
                href=""
                class="text-teal"
                @click.prevent="$zendesk.show()"
                >Live Chat</a
              ><br />
            </div>
            <div class="social-media">
              <div class="social-media-container"></div>
            </div>
          </div>
        </template>
      </div>

      <div
        class="s-navbar-overlay"
        :class="{ opened: xIsNavbarOpen }"
        @click="xCloseMobileNavbar"
      ></div>

      <div
        class="s-mobile-navbar-container flex flex-row items-center"
        :class="{ opened: xIsNavbarOpen }"
      >
        <div
          class="s-mobile-navbar-hamburger-menu flex items-center justify-center"
          @click="xToggleMobileNavbar"
        >
          <IconHamburgerMenu class="s-hamburger-svg" />
        </div>

        <div class="s-mobile-navbar-items flex flex-row items-center justify-between">
          <NuxtLink
            to="/"
            class="flex items-center ml-2 lg:ml-6 s-mobile-navbar-logo"
          >
            <img
              src="/images/brand/mobile-logo.svg?url"
              alt="The Flowery"
            />
          </NuxtLink>
          <nav class="flex items-center text-base justify-center ml-auto h-full space-x-2 px-[5px]">
            <a
              id="conversation-badge"
              class="text-white s-top-bar-link-svg s-top-bar-account cursor-pointer text-right"
              @click="openChatBubble"
              @touchstart="openChatBubble"
            >
              <img
                class="s-top-bar-svg"
                src="@/assets/icon/svg/chat-bubble.svg?url"
                alt=""
              />
              <span
                id="unread-indicator"
                class="s-badge s-badge-secondary"
                >0</span
              >
            </a>
            <NuxtLink
              v-if="xIsAuthenticated"
              class="text-white s-top-bar-link-svg s-top-bar-account cursor-pointer text-right"
              :to="'/profile/dashboard'"
            >
              <img
                class="s-top-bar-svg"
                src="@/assets/icon/svg/account.svg?url"
                alt=""
              />
            </NuxtLink>
            <a
              v-else
              class="text-white s-top-bar-link-svg s-top-bar-account cursor-pointer text-right"
              @click="xOpenLoginPopup"
            >
              <img
                class="s-top-bar-svg"
                src="@/assets/icon/svg/account.svg?url"
                alt=""
              />
            </a>
            <RenderlessXCart v-slot="{ xShoppingCart }">
              <NuxtLink
                :to="'/cart'"
                class="text-white s-top-bar-link-svg"
              >
                <img
                  class="s-top-bar-svg"
                  src="@/assets/icon/svg/cart.svg?url"
                  alt=""
                />
                <span class="s-badge s-badge-secondary">{{ xShoppingCart.totalQuantity }}</span>
              </NuxtLink>
            </RenderlessXCart>
            <ShopPageLink class="s-highlighted inline-flex items-center px-8 h-full ml-6"> Order now </ShopPageLink>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import _phone from '@/utils/filters/phone'
  import _shortName from '@/utils/filters/short-name'

  import { $api } from '@/utils/api'

  export default {
    name: 'MobileNavbar',
    emits: ['close', 'open', 'hyperlink'],
    data: () => ({
      count: 0,
      placesMenuOpen: false
    }),
    computed: {
      ...mapGetters('authuser', ['xCurrentUser', 'xIsAuthenticated']),
      ...mapGetters(['GET_HEADER_DATA', 'xIsNavbarOpen']),

      phone() {
        return _phone(this?.xCurrentUser?.phone || null)
      },

      shortname() {
        return _shortName(this?.xCurrentUser)
      },

      addressList() {
        return this?.xCurrentUser?.addresses || null
      }
    },
    async mounted() {
      this.getCartCount()
    },
    methods: {
      ...mapActions('auth', ['xLogout', 'xOpenLoginPopup']),
      ...mapActions(['xToggleMobileNavbar', 'xCloseMobileNavbar']),
      ...mapActions('cart', ['xGetCartCount']),

      closeMobileNavbar() {
        this.$store.dispatch('xCloseMobileNavbar')
      },
      /**
       * Called when salve-link component emits a hyperlink event
       * @param link
       */
      gotHyperlink(link) {
        this.$emit('hyperlink', link)
      },

      getCategoryLink(link) {
        // If this is a full hyperlink just return as is
        if (link.slice(0, 4) === 'http') {
          return link
        }

        // This is a shop link so pass back as a relative path from shop
        return link
      },

      async getCartCount() {
        this.count = await this.xGetCartCount()
      },
      async logoutCurrentUser() {
        await this.xLogout()
        const url = window.location.href
        window.location.href = url
      },
      openChatBubble() {
        const unreadIndicator = document.querySelector('#unread-indicator')

        const populateUnreadIndicator = (count) => {
          if (!count) return resetUnreadIndicator()

          unreadIndicator.innerHTML = count
        }

        const resetUnreadIndicator = () => {
          unreadIndicator.innerHTML = 0
        }

        zE('messenger', 'open')

        zE('messenger:on', 'unreadMessages', (count) => {
          console.log(count)
          populateUnreadIndicator(count)
        })
      },
      openPlacesMenu(e) {
        this.placesMenuOpen = true
        this.$emit('open', e)
      },
      closePlacesMenu() {
        this.placesMenuOpen = false
        this.$emit('close')
      },
      openModal(modal) {
        this.$emit('open', modal)
      }
    }
  }
</script>

<style lang="postcss">
  @media screen and (max-width: 1024px) {
    .s-navbar-menu {
      visibility: hidden;
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      height: 100vh;
      background: #fff;
      transition: all 0.5s ease-in-out 0s;
      transform: translate3d(-100%, 0, 0);
      @apply z-20 fixed w-full;

      &.opened {
        visibility: visible;
        transition: transform 0.5s ease-in-out 0s;
        transform: translate3d(0, 0, 0);
      }

      .s-menu-header {
        border-bottom: 1px solid #eef1f3;
      }

      .s-menu-logo {
        padding-top: 11px;
        padding-bottom: 15px;
      }

      .s-menu-btn-order {
        background: #3fb9c5;
        font-family: DM Mono, serif;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.375px;
        color: #fff;
        padding: 19px 0 17px;
      }

      .s-menu-personal-info {
        flex-direction: column;
        @apply p-2 py-0 pb-0;

        .allotment {
          .allotment-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            &:first-child {
              border-right: 1px solid #eef1f3;
            }

            .item {
              font-weight: 500;
              color: #4899b9;
            }

            .header {
              font-weight: 500;
              text-transform: uppercase;
              color: #7c93a5;
            }
          }
        }

        .s-menu-btn-my-acc {
          @apply p-4 items-center self-center;

          .s-menu-btn-my-acc-user-svg {
            width: 45px;
            height: 45px;
            @apply self-center mr-3;
          }

          .s-menu-btn-my-acc-content {
            font-weight: 500;
            font-family: DM Mono, serif;
            background: #fff;
            border: 1px solid #f3b6d0;
            box-shadow: 0 0 7px #f3b6d0;

            .header {
              text-transform: uppercase;
              color: #7c93a5;
            }

            .user {
              color: #3fb9c5;
              @apply text-base;
            }
          }

          .s-menu-btn-my-acc-arrow-right {
            width: 10px;
            height: 14px;
            margin-left: 20px;
            fill: #002d52;
          }
        }
      }

      .s-menu-weed,
      .s-menu-best-buds,
      .s-menu-resources {
        .s-menu-weed-header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #eef1f3;
          align-items: center;

          .header-weed {
            color: #3fb9c5;
          }

          .s-arrow {
            margin-right: 18px;
          }
        }

        .s-menu-weed-content {
          display: flex;
          flex-wrap: wrap;
          @apply p-3;

          .weed-item {
            font-weight: 500;
            color: #000;
            @apply font-alt;
            @apply p-1;
          }

          .weed-item-710 {
            width: 77px;
            height: 11px;
          }

          .weed-item-flowery {
            width: 75px;
          }

          .weed-item-packwoods {
            width: 82px;
          }
        }
      }
    }

    .s-menu-resources-content {
      @apply font-alt;
      @apply px-6 text-sm font-semibold mb-10 leading-6;

      .address {
        color: #000;
      }

      .social-media-container {
        margin-top: 14px;
        display: flex;
        justify-content: space-around;
        width: 100%;
      }
    }

    .s-dropdown-resource-link {
      @apply font-alt;
    }

    .arrow-top {
      transform: rotate(-90deg);
    }

    .arrow-down {
      transform: rotate(90deg);
    }

    .s-dropdown-resource-title {
      @apply font-main;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 18px;
      color: #f3b6d0;
    }

    .s-top-bar-address-dropdown {
      @apply text-center no-underline px-0;

      > span {
        @apply text-teal no-underline;
      }

      > svg {
        @apply text-teal no-underline;
      }

      .s-dropdown-menu {
        position: absolute;
        text-align: left;
        height: auto;
        z-index: 10;
        background: #fff;
        box-shadow: 0 7px 8px 0 rgb(0 0 0 / 20%);
        top: 49px !important;

        width: 325px;
        @apply left-0 px-0;
        @media screen and (max-width: 800px) {
          width: 100%;
        }

        .s-dropdown-menu-item {
          label {
            @apply text-xs;
          }

          .s-dropdown-menu-default {
            height: 25px;
            transform: translateY(50%);
          }
        }
      }

      &:hover,
      &:active {
        .s-top-bar-chevron {
          transform: scaleY(-1);
        }

        .s-dropdown-menu {
          display: block;
        }
      }
    }

    .s-dropdown-menu-item-checkbox:checked + *::before {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 8.13813L6.84888 15.0001L20 1.86195L18.1119 0L6.84888 11.25L1.86191 6.26306L0 8.13813Z' fill='%233FB9C5'/%3E%3C/svg%3E%0A");
    }

    .s-dropdown-canabis-icon {
      width: 34px;
      height: 40px;
    }

    .s-navbar-overlay {
      visibility: hidden;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background: #002d52;
      mix-blend-mode: normal;
      transition: all 0.5s ease 0s;
      opacity: 0;
      @apply z-10 fixed;

      &.opened {
        transition: opacity 0.5s ease 0s;
        opacity: 0.75;
        visibility: visible;
      }
    }

    .s-mobile-navbar {
      height: 60px;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      @apply bg-white;
    }

    .s-mobile-navbar-container {
      width: 100vw;
      height: 100%;
      overflow: hidden;
      transition: all 0.5s ease 0s;
      transform: translate3d(0, 0, 0);
      @apply relative;

      &.opened {
        transition: transform 0.5s ease 0s;
        transform: translate3d(330px, 0, 0);
      }
    }

    .s-mobile-navbar-hamburger-menu {
      width: 67px;
      height: 100%;
      cursor: pointer;

      .s-hamburger-arrow {
        color: #000;
        margin-right: 7px;
      }
    }

    .s-mobile-navbar-items {
      background: #000;
      @apply h-full w-screen;
    }

    .s-mobile-navbar-logo {
      width: 200px;

      @media screen and (max-width: 400px) {
        width: 152px;
      }
    }

    .s-top-bar-link-svg {
      position: relative;
      display: inline-block;
    }

    .s-badge {
      position: absolute;
      top: 0;
      right: 0;
    }

    .s-badge-secondary {
      background: #f3b6d0ff;
      color: #002d52ff;
      @apply font-alt;
      @apply text-xs rounded-full px-1;
    }

    .s-highlighted {
      background: #3fb9c5ff;
      @apply font-alt;
      text-transform: capitalize;
      color: #fff;
      @media screen and (max-width: 767px) {
        font-size: 12px;
        max-width: 129px;
      }

      @media screen and (max-width: 553px) {
        display: none;
      }
    }

    .s-highlighted:hover {
      cursor: pointer;
      background: #f3b6d0ff;
      color: #002d52ff;
    }

    .s-margin-left-auto {
      margin-left: auto;
    }

    .s-top-bar-svg {
      @media screen and (max-width: 767px) {
      }
    }

    .s-top-bar-account {
    }

    .s-top-bar-basket {
    }

    .s-mobile-navbar-menu-btn {
      background: #fff;
      width: 60px;
      height: 100%;
      display: flex;
      justify-content: center;
      justify-items: center;
    }

    .s-mobile-navbar-menu-btn img {
      margin-right: 4px;
      margin-left: 4px;
    }
    .jobitem {
      padding: 0 !important;
    }
  }
</style>
