<template>
  <svg
    width="42px"
    height="42px"
    viewBox="0 0 42 42"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Account icon</title>
    <g
      id="Style-Guide"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Account-icon">
        <circle
          id="Oval"
          stroke="#3FB9C5"
          stroke-width="2"
          fill="#000000"
          cx="21"
          cy="21"
          r="20"
        />
        <g
          id="Group-9"
          transform="translate(14.000000, 10.000000)"
          stroke="#FFFFFF"
          stroke-width="1.5"
        >
          <circle
            id="Oval"
            cx="6.9820764"
            cy="5"
            r="4.25"
          />
          <path
            id="Path-3"
            d="M0,18 L0.394931775,15.5405897 C0.784233891,13.1162378 2.87627751,11.3333333 5.33168755,11.3333333 L8.60656395,11.3333333 C11.0170079,11.3333333 13.0843252,13.0531056 13.5230481,15.4232875 L14,18 L14,18"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
