<template>
  <div @click="emitLink">
    <NuxtLink
      v-if="!isExternalLink"
      :to="to"
    >
      <slot />
    </NuxtLink>
    <a
      v-if="isExternalLink"
      :href="to"
    >
      <slot />
    </a>
  </div>
</template>

<script>
  export default {
    name: 'SalveLink',

    props: {
      to: {
        type: String,
        required: true
      }
    },

    computed: {
      isExternalLink() {
        return this.to.slice(0, 4) === 'http'
      }
    },

    methods: {
      emitLink() {
        const url = decodeURIComponent(this?.to?.split('?')?.[1] || '')
        this.$emit('hyperlink', url)
      }
    }
  }
</script>
