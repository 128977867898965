<template>
  <NuxtLink to="/shop">
    <slot>SHOP</slot>
  </NuxtLink>
</template>

<script>
  export default {
    name: 'ShopLink'
  }
</script>
