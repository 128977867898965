<template>
  <div class="accordion-header cursor-pointer">
    <div class="accordion-title">
      <slot />
    </div>
    <IconCaretDown :class="['accordion-arrow', { 'arrow-down': open }, { 'arrow-right': !open }]" />
  </div>
</template>

<script>
  export default {
    name: 'AccordionHeader',

    props: {
      /**
       * If true the accordion will be open. If false it will be closed
       */
      open: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped lang="postcss">
  .accordion-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eef1f3;
    align-items: center;
    padding: 15px 0;
  }

  .accordion-arrow {
    margin-right: 18px;
  }

  .accordion-title {
    color: #3fb9c5;
  }

  .arrow-down {
    transition: all 0.25s;
    transform: rotate(90deg);
  }

  .arrow-right {
    transition: all 0.25s;
    transform: rotate(0deg);
  }
</style>
