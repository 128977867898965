<template>
  <div>
    <div
      class="headermodals"
      :class="currentModal === 'delivery' ? 'block' : 'hidden'"
    >
      <ModalDeliveryAndRegion
        :show="currentModal === 'delivery'"
        @close="closeAllModals"
      />
    </div>
    <header class="s-header mb-8 md:mb-0">
      <PageHeaderTopNavbar
        ref="topNavBar"
        @open="openModal"
        @close="closeAllModals"
      />
      <PageHeaderNavbar
        :top-nav-bar="() => topNavBar"
        @hyperlink="$emit('hyperlink', $event)"
      />
      <PageHeaderMobileNavbar
        @hyperlink="$emit('hyperlink', $event)"
        @open="openModal"
        @close="closeAllModals"
      />
    </header>
  </div>
</template>

<script setup>
  const topNavBar = ref(null)
</script>

<script>
  export default {
    data() {
      return {
        currentModal: null
      }
    },

    methods: {
      openModal(modal) {
        this.currentModal = modal
      },

      closeAllModals() {
        this.currentModal = null
      }
    }
  }
</script>

<style lang="postcss">
  .s-header {
    position: relative;

    @media screen and (max-width: 800px) {
      width: 100%;
      display: flex;
      position: relative;
      z-index: 1000;
    }
  }
</style>
