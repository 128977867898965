<template>
  <AccordionContainer>
    <template v-if="to">
      <NuxtLink :to="to">
        <AccordionHeader>
          <slot />
        </AccordionHeader>
      </NuxtLink>
    </template>

    <template v-if="!to">
      <AccordionHeader @click="$emit('click')">
        <slot />
      </AccordionHeader>
    </template>
  </AccordionContainer>
</template>

<script>
  /**
   * A component that looks like an accordion but acts like a hyperlink. Useful for creating menus
   */

  export default {
    name: 'AccordionLink',

    props: {
      /**
       * The place that this hyperlink should redirect you when clicked
       */
      to: {
        type: String,
        default: null
      }
    }
  }
</script>
