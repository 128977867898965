<template>
  <AccordionContainer>
    <AccordionHeader
      :open="isOpen"
      @click="toggleAccordion"
    >
      {{ title }}
    </AccordionHeader>
    <transition
      v-if="isOpen"
      name="fade"
    >
      <slot />
    </transition>
  </AccordionContainer>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Accordion'
      },

      /**
       * If true the accordion will be open. If false it will be closed
       */
      open: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        isOpen: this.open
      }
    },

    watch: {
      /**
       * IF opened prop changes we update the open/close state of the accordion
       */
      open() {
        this.isOpen = this.open
      }
    },

    methods: {
      toggleAccordion() {
        this.isOpen = !this.isOpen
      }
    }
  }
</script>
