<template>
  <div class="px-6">
    <slot />
  </div>
</template>

<script>
  /**
   * A container we build accordion menus in
   */
  export default {
    name: 'AccordionContainer'
  }
</script>
